import { ImageSourceHelper } from '@sortlist-frontend/media';
import { TFunction } from '@sortlist-frontend/translation/ssr';

const s3BucketUrl = process.env.NEXT_PUBLIC_S3_BUCKET;

const adsterLogo = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/agency-logos/adster-logo.webp`);
const adsterCover = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/cover-pictures/cover-01.webp`);
const adsterWork1 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/work-thumbnails/work-01.webp`);
const adsterWork2 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/work-thumbnails/work-02.webp`);
const adsterWork3 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/work-thumbnails/work-03.webp`);
const adsterWork4 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/work-thumbnails/work-04.webp`);

const floadLogo = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/agency-logos/fload-logo.webp`);
const floadCover = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/cover-pictures/cover-02.webp`);
const floadWork1 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/work-thumbnails/work-05.webp`);
const floadWork2 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/work-thumbnails/work-06.webp`);
const floadWork3 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/work-thumbnails/work-07.webp`);
const floadWork4 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/work-thumbnails/work-08.webp`);

const rawLogo = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/agency-logos/raw-logo.webp`);
const rawCover = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/cover-pictures/cover-03.webp`);
const rawWork1 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/work-thumbnails/work-09.webp`);
const rawWork2 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/work-thumbnails/work-10.webp`);
const rawWork3 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/work-thumbnails/work-11.webp`);
const rawWork4 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/work-thumbnails/work-12.webp`);

const swireLogo = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/agency-logos/swire-logo.webp`);
const swireCover = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/cover-pictures/cover-04.webp`);
const swireWork1 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/work-thumbnails/work-13.webp`);
const swireWork2 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/work-thumbnails/work-14.webp`);
const swireWork3 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/work-thumbnails/work-15.webp`);
const swireWork4 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/work-thumbnails/work-16.webp`);

const yourCompanyLogo = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/agency-logos/youragency-logo.webp`);
const yourCompanyCover = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/cover-pictures/cover-05.webp`);
const yourCompanyWork1 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/work-thumbnails/work-17.webp`);
const yourCompanyWork2 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/work-thumbnails/work-18.webp`);
const yourCompanyWork3 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/work-thumbnails/work-19.webp`);
const yourCompanyWork4 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/work-thumbnails/work-20.webp`);

export type FakeAgency = {
  id: string;
  name: string;
  catchPhrase?: string;
  description?: string;
  logoSrc: string;
  skills: string[];
  location: string;
  membersCount: number;
  languages: string[];
  budgetStart: string;
  rating?: number;
  coverSrc?: string;
  reviewsCount?: number;
  promoted?: boolean;
  verified?: boolean;
  works: {
    id: string;
    title: string;
    imageSrc: string;
  }[];
};

export const getFakeAgencies = (t: TFunction): FakeAgency[] => {
  return [
    {
      id: 'rawDesign',
      name: 'Raw Design',
      catchPhrase: t('common:fakeAgencies.rawDesign.catchPhrase'),
      description: t('common:fakeAgencies.rawDesign.description'),
      logoSrc: rawLogo,
      skills: [
        t('common:expertises.branding.name'),
        t('common:expertises.design.name'),
        t('common:expertises.onlineAd.name'),
      ],
      location: t('common:fakeAgencies.locations.brussels'),
      languages: [t('common:fakeAgencies.languages.french'), t('common:fakeAgencies.languages.english')],
      membersCount: 4,
      rating: 5,
      reviewsCount: 12,
      coverSrc: rawCover,
      budgetStart: t('common:fakeAgencies.budget', { price: '1.000' }),
      works: [
        {
          id: 'rawWork1',
          title: 'Raw work 01',
          imageSrc: rawWork1,
        },
        {
          id: 'rawWork1',
          title: 'Raw work 01',
          imageSrc: rawWork2,
        },
        {
          id: 'rawWork1',
          title: 'Raw work 01',
          imageSrc: rawWork3,
        },
        {
          id: 'rawWork1',
          title: 'Raw work 01',
          imageSrc: rawWork4,
        },
      ],
    },
    {
      id: 'swire',
      name: 'Swire',
      logoSrc: swireLogo,

      skills: [
        t('common:expertises.branding.name'),
        t('common:expertises.graphicDesign.name'),
        t('common:expertises.ecommerce.name'),
      ],
      location: t('common:fakeAgencies.locations.brussels'),
      languages: [
        t('common:fakeAgencies.languages.french'),
        t('common:fakeAgencies.languages.english'),
        t('common:fakeAgencies.languages.polish'),
        t('common:fakeAgencies.languages.spanish'),
        t('common:fakeAgencies.languages.dutch'),
      ],
      rating: 5,
      reviewsCount: 12,
      membersCount: 30,
      coverSrc: swireCover,
      budgetStart: t('common:fakeAgencies.budget', { price: '10.000' }),
      works: [
        {
          id: 'swireWork1',
          title: 'Swire work 01',
          imageSrc: swireWork1,
        },
        {
          id: 'swireWork1',
          title: 'Swire work 02',
          imageSrc: swireWork2,
        },
        {
          id: 'swireWork1',
          title: 'Swire work 03',
          imageSrc: swireWork3,
        },
        {
          id: 'swireWork1',
          title: 'Swire work 04',
          imageSrc: swireWork4,
        },
      ],
    },
    {
      id: 'fload',
      name: 'Fload',
      logoSrc: floadLogo,
      skills: [
        t('common:expertises.onlineAd.name'),
        t('common:expertises.digitalMarketing.name'),
        t('common:expertises.contentMarketing.name'),
        t('common:expertises.socialMedia.name'),
        t('common:expertises.mediaPlanning.name'),
        t('common:expertises.branding.name'),
      ],
      location: t('common:fakeAgencies.locations.antwerpen'),
      languages: [
        t('common:fakeAgencies.languages.dutch'),
        t('common:fakeAgencies.languages.french'),
        t('common:fakeAgencies.languages.english'),
      ],
      rating: 5,
      reviewsCount: 12,
      membersCount: 8,
      coverSrc: floadCover,
      budgetStart: t('common:fakeAgencies.budget', { price: '1.000' }),
      works: [
        {
          id: 'floadWork1',
          title: 'Fload work 01',
          imageSrc: floadWork1,
        },
        {
          id: 'floadWork1',
          title: 'Fload work 02',
          imageSrc: floadWork2,
        },
        {
          id: 'floadWork1',
          title: 'Fload work 03',
          imageSrc: floadWork3,
        },
        {
          id: 'floadWork1',
          title: 'Fload work 04',
          imageSrc: floadWork4,
        },
      ],
    },
    {
      id: 'adster',
      name: 'Adster',
      logoSrc: adsterLogo,
      skills: [
        t('common:expertises.appDevelopment.name'),
        t('common:expertises.digitalMarketing.name'),
        t('common:expertises.uxAgency.name'),
        t('common:expertises.webDevelopment.name'),
        t('common:expertises.branding.name'),
        t('common:expertises.graphicDesign.name'),
        t('common:expertises.ecommerce.name'),
      ],
      rating: 5,
      reviewsCount: 12,
      location: t('common:fakeAgencies.locations.paris'),
      languages: [t('common:fakeAgencies.languages.english'), t('common:fakeAgencies.languages.french')],
      membersCount: 15,
      coverSrc: adsterCover,
      budgetStart: t('common:fakeAgencies.budget', { price: '1.000' }),
      works: [
        {
          id: 'adsterWork1',
          title: 'Adster work 01',
          imageSrc: adsterWork1,
        },
        {
          id: 'adsterWork1',
          title: 'Adster work 02',
          imageSrc: adsterWork2,
        },
        {
          id: 'adsterWork1',
          title: 'Adster work 03',
          imageSrc: adsterWork3,
        },
        {
          id: 'adsterWork1',
          title: 'Adster work 04',
          imageSrc: adsterWork4,
        },
      ],
    },
    {
      id: 'yourCompany',
      name: 'yourCompany',
      logoSrc: yourCompanyLogo,
      description: t('common:fakeAgencies.yourCompany.description'),
      catchPhrase: t('common:fakeAgencies.yourCompany.catchPhrase'),
      skills: [
        t('common:expertises.appDevelopment.name'),
        t('common:expertises.digitalMarketing.name'),
        t('common:expertises.uxAgency.name'),
        t('common:expertises.webDevelopment.name'),
        t('common:expertises.branding.name'),
        t('common:expertises.graphicDesign.name'),
        t('common:expertises.ecommerce.name'),
      ],
      rating: 5,
      reviewsCount: 56,
      promoted: true,
      location: t('common:fakeAgencies.locations.paris'),
      languages: [t('common:fakeAgencies.languages.english'), t('common:fakeAgencies.languages.french')],
      membersCount: 15,
      coverSrc: yourCompanyCover,
      budgetStart: t('common:fakeAgencies.budget', { price: '1.000' }),
      works: [
        {
          id: 'yourCompanyWork1',
          title: 'yourCompany work 01',
          imageSrc: yourCompanyWork1,
        },
        {
          id: 'yourCompanyWork2',
          title: 'yourCompany work 02',
          imageSrc: yourCompanyWork2,
        },
        {
          id: 'yourCompanyWork3',
          title: 'yourCompany work 03',
          imageSrc: yourCompanyWork3,
        },
        {
          id: 'yourCompanyWork4',
          title: 'yourCompany work 04',
          imageSrc: yourCompanyWork4,
        },
      ],
    },
  ];
};
