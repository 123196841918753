import { ImageSourceHelper } from '@sortlist-frontend/media';
import { TFunction } from '@sortlist-frontend/translation/ssr';

const s3BucketUrl = process.env.NEXT_PUBLIC_S3_BUCKET;

// Male
const male1 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/user-avatars/male/user-male-01.webp`);
const male2 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/user-avatars/male/user-male-02.webp`);
const male3 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/user-avatars/male/user-male-03.webp`);
const male4 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/user-avatars/male/user-male-04.webp`);
const male5 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/user-avatars/male/user-male-05.webp`);
const male6 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/user-avatars/male/user-male-06.webp`);
const male7 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/user-avatars/male/user-male-07.webp`);
const male8 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/user-avatars/male/user-male-08.webp`);
const male9 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/user-avatars/male/user-male-09.webp`);
const male10 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/user-avatars/male/user-male-10.webp`);

// Female
const female1 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/user-avatars/female/user-female-01.webp`);
const female2 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/user-avatars/female/user-female-02.webp`);
const female3 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/user-avatars/female/user-female-03.webp`);
const female4 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/user-avatars/female/user-female-04.webp`);
const female5 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/user-avatars/female/user-female-05.webp`);
const female6 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/user-avatars/female/user-female-06.webp`);
const female7 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/user-avatars/female/user-female-07.webp`);
const female8 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/user-avatars/female/user-female-08.webp`);
const female9 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/user-avatars/female/user-female-09.webp`);
const female10 = ImageSourceHelper.fromUrl(`${s3BucketUrl}/fake/user-avatars/female/user-female-10.webp`);

export type FakeUser = {
  id: string;
  name: string;
  jobTitle?: string;
  rating?: number;
  avatarSrc: string;
};

export const getFakeUsers = (t: TFunction): FakeUser[] => {
  return [
    {
      id: 'cooperLubin',
      name: 'Cooper Lubin',
      jobTitle: t('common:jobTitles.marketingDirector'),
      rating: 5,
      avatarSrc: male1,
    },
    {
      id: 'robertNosera',
      name: 'Robert Nosera',
      jobTitle: t('common:jobTitles.productManager'),
      rating: 4.5,
      avatarSrc: male2,
    },
    {
      id: 'seanPerry',
      name: 'Sean Perry',
      jobTitle: t('common:jobTitles.marketer'),
      rating: 4,
      avatarSrc: male3,
    },
    {
      id: 'richardMcgee',
      name: 'Richard Mcgee',
      jobTitle: t('common:jobTitles.customerSuccessManager'),
      rating: 5,
      avatarSrc: male4,
    },
    {
      id: 'henryLenvy',
      name: 'Henry Lenvy',
      jobTitle: t('common:jobTitles.publicRelations'),
      rating: 5,
      avatarSrc: male5,
    },
    {
      id: 'joshRiddle',
      name: 'Josh Riddle',
      jobTitle: t('common:jobTitles.projectManager'),
      rating: 3.5,
      avatarSrc: male6,
    },
    {
      id: 'steveRaymond',
      name: 'Steve Raymond',
      jobTitle: t('common:jobTitles.engineer'),
      rating: 5,
      avatarSrc: male7,
    },
    {
      id: 'davidSmith',
      name: 'David Smith',
      jobTitle: t('common:jobTitles.videoEditor'),
      rating: 5,
      avatarSrc: male8,
    },
    {
      id: 'marcStanley',
      name: 'Marc Stanley',
      jobTitle: t('common:jobTitles.ceo'),
      rating: 5,
      avatarSrc: male9,
    },
    {
      id: 'williamCrawford',
      name: 'William Crawford',
      jobTitle: t('common:jobTitles.eventsManager'),
      rating: 4.5,
      avatarSrc: male10,
    },
    {
      id: 'susanOswalt',
      name: 'Susan Oswalt',
      jobTitle: t('common:jobTitles.salesDirector'),
      rating: 5,
      avatarSrc: female1,
    },
    {
      id: 'emilyRhodes',
      name: 'Emily Rhodes',
      jobTitle: t('common:jobTitles.restaurantManager'),
      rating: 5,
      avatarSrc: female2,
    },
    {
      id: 'sarahHubert',
      name: 'Sarah Hubert',
      jobTitle: t('common:jobTitles.communityManager'),
      rating: 5,
      avatarSrc: female3,
    },
    {
      id: 'sarahHubert',
      name: 'Cooper Lubin',
      jobTitle: t('common:jobTitles.marketingDirector'),
      rating: 4.5,
      avatarSrc: female4,
    },
    {
      id: 'lauraMasson',
      name: 'Laura Masson',
      jobTitle: t('common:jobTitles.contentStrategist'),
      rating: 4,
      avatarSrc: female5,
    },
    {
      id: 'fannyBernard',
      name: 'Fanny Bernard',
      jobTitle: t('common:jobTitles.entrepreneur'),
      rating: 5,
      avatarSrc: female6,
    },
    {
      id: 'melissaCarter',
      name: 'Melissa Carter',
      jobTitle: t('common:jobTitles.cto'),
      rating: 5,
      avatarSrc: female7,
    },
    {
      id: 'evaBond',
      name: 'Eva Bond',
      jobTitle: t('common:jobTitles.copywriter'),
      rating: 5,
      avatarSrc: female8,
    },
    {
      id: 'amberVincent',
      name: 'Amber Vincent',
      jobTitle: t('common:jobTitles.musician'),
      rating: 5,
      avatarSrc: female9,
    },
    {
      id: 'Emily Moss',
      name: 'EmilyMoss',
      jobTitle: t('common:jobTitles.director'),
      rating: 5,
      avatarSrc: female10,
    },
  ];
};
