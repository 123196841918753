import { ImageSourceHelper } from '@sortlist-frontend/media';
import { TFunction } from '@sortlist-frontend/translation/ssr';

const s3BucketUrl = process.env.NEXT_PUBLIC_S3_BUCKET;

export type MinimalQuoteData = {
  id: string;
  author?: string;
  hash?: string;
  citation: string;
};

export type QuoteData = {
  slug?: string;
  logoSrc?: string;
  imgAlt: string;
  authorRole: string;
} & MinimalQuoteData;

// TODO: Move the quote data to public-db. It's now impossible to point towards the new pages as the slug are unknown in here. Commenting the slug for now.

export const clientQuotes = (t: TFunction): QuoteData[] => {
  return [
    {
      id: 'belges',
      // slug: '1001-belges',
      logoSrc: ImageSourceHelper.fromUrl(`${s3BucketUrl}/stories/1001-belges-thumbnail.webp`),
      author: 'Delphine Leriche',
      authorRole: t('quotes:belges.authorRole'),
      imgAlt: t('quotes:belges.imgAlt'),
      citation: t('quotes:belges.quote'),
    },
    {
      id: 'aubry',
      // slug: 'aubry',
      logoSrc: ImageSourceHelper.fromUrl(`${s3BucketUrl}/stories/aubry-thumbnail.webp`),
      author: 'Alexis Degen',
      authorRole: t('quotes:aubry.authorRole'),
      imgAlt: t('quotes:aubry.imgAlt'),
      citation: t('quotes:aubry.quote'),
    },
    {
      id: 'bourbon',
      logoSrc: ImageSourceHelper.fromUrl(`${s3BucketUrl}/stories/bourbon-thumbnail.webp`),
      author: 'Jessica Fabre',
      authorRole: t('quotes:bourbon.authorRole'),
      imgAlt: t('quotes:bourbon.imgAlt'),
      citation: t('quotes:bourbon.quote'),
    },
    {
      id: 'composil',
      // slug: 'composil',
      logoSrc: ImageSourceHelper.fromUrl(`${s3BucketUrl}/stories/composil-thumbnail.webp`),
      author: 'Jean Minne',
      authorRole: t('quotes:composil.authorRole'),
      imgAlt: t('quotes:composil.imgAlt'),
      citation: t('quotes:composil.quote'),
    },
    {
      id: 'mega',
      // slug: 'mega',
      logoSrc: ImageSourceHelper.fromUrl(`${s3BucketUrl}/stories/mega-thumbnail.webp`),
      author: 'Kenneth De Maeyer',
      authorRole: t('quotes:mega.authorRole'),
      imgAlt: t('quotes:mega.imgAlt'),
      citation: t('quotes:mega.quote'),
    },
    {
      id: 'newPharma',
      // slug: 'new-pharma',
      logoSrc: ImageSourceHelper.fromUrl(`${s3BucketUrl}/stories/new-pharma-thumbnail.webp`),
      author: 'Ilse Van Dyck',
      authorRole: t('quotes:newPharma.authorRole'),
      imgAlt: t('quotes:newPharma.imgAlt'),
      citation: t('quotes:newPharma.quote'),
    },
    {
      id: 'trends',
      logoSrc: ImageSourceHelper.fromUrl(`${s3BucketUrl}/stories/trends-thumbnail.webp`),
      author: 'Jean-Baptiste Quesnay',
      authorRole: t('quotes:trends.authorRole'),
      imgAlt: t('quotes:trends.imgAlt'),
      citation: t('quotes:trends.quote'),
    },
    {
      id: 'oniryx',
      // slug: 'oniryx',
      logoSrc: ImageSourceHelper.fromUrl(`${s3BucketUrl}/stories/oniryx-thumbnail.webp`),
      author: 'Chantal Charbonnier',
      authorRole: t('quotes:oniryx.authorRole'),
      imgAlt: t('quotes:oniryx.imgAlt'),
      citation: t('quotes:oniryx.quote'),
    },
    {
      id: 'primrose',
      logoSrc: ImageSourceHelper.fromUrl(`${s3BucketUrl}/stories/primrose-thumbnail.webp`),
      author: 'Lynn Van Wilderode',
      authorRole: t('quotes:primrose.authorRole'),
      imgAlt: t('quotes:primrose.imgAlt'),
      citation: t('quotes:primrose.quote'),
    },
    {
      id: 'softscale',
      // slug: 'softscale',
      logoSrc: ImageSourceHelper.fromUrl(`${s3BucketUrl}/stories/softscale-thumbnail.webp`),
      author: 'Damien Thayse',
      authorRole: t('quotes:softscale.authorRole'),
      imgAlt: t('quotes:softscale.imgAlt'),
      citation: t('quotes:softscale.quote'),
    },
    {
      id: 'lapresse',
      // slug: 'lapresse',
      logoSrc: ImageSourceHelper.fromUrl(`${s3BucketUrl}/stories/lapresse-thumbnail.webp`),
      author: 'Tatiana Debrabandere',
      authorRole: t('quotes:lapresse.authorRole'),
      imgAlt: t('quotes:lapresse.imgAlt'),
      citation: t('quotes:lapresse.quote'),
    },
    {
      id: 'thomasCook',
      logoSrc: ImageSourceHelper.fromUrl(`${s3BucketUrl}/stories/thomas-cook-thumbnail.webp`),
      author: "Sylvie T'Jaeckx",
      authorRole: t('quotes:thomasCook.authorRole'),
      imgAlt: t('quotes:thomasCook.imgAlt'),
      citation: t('quotes:thomasCook.quote'),
    },
    {
      id: 'solvay',
      // slug: 'solvay',
      logoSrc: ImageSourceHelper.fromUrl(`${s3BucketUrl}/stories/solvay-thumbnail.webp`),
      author: 'Alice Tranchant',
      authorRole: t('quotes:solvay.authorRole'),
      imgAlt: t('quotes:solvay.imgAlt'),
      citation: t('quotes:solvay.quote'),
    },
    {
      id: 'redCrossBelgium',
      // slug: 'red-cross-belgium',
      logoSrc: ImageSourceHelper.fromUrl(`${s3BucketUrl}/stories/red-cross-belgium-thumbnail.webp`),
      author: 'Tiziana d"Olne',
      authorRole: t('quotes:redCrossBelgium.authorRole'),
      imgAlt: t('quotes:redCrossBelgium.imgAlt'),
      citation: t('quotes:redCrossBelgium.quote'),
    },
  ];
};

export const agencyQuotes = (t: TFunction): QuoteData[] => {
  return [
    {
      id: 'agence148',
      // slug: 'agence-148',
      logoSrc: ImageSourceHelper.fromUrl(`${s3BucketUrl}/stories/agence-148-thumbnail.webp`),
      author: 'Alexia Berrebi',
      authorRole: t('quotes:agence148.authorRole'),
      imgAlt: t('quotes:agence148.imgAlt'),
      citation: t('quotes:agence148.quote_V2'),
    },
    {
      id: 'alpaga',
      // slug: 'alpaga',
      logoSrc: ImageSourceHelper.fromUrl(`${s3BucketUrl}/stories/alpaga-thumbnail.webp`),
      author: 'Cedric Faltin',
      authorRole: t('quotes:alpaga.authorRole'),
      imgAlt: t('quotes:alpaga.imgAlt'),
      citation: t('quotes:alpaga.quote'),
    },
    {
      id: 'brandominus',
      // slug: 'brandominus',
      logoSrc: ImageSourceHelper.fromUrl(`${s3BucketUrl}/stories/brandominus-thumbnail.webp`),
      author: 'Sergio Serrano',
      authorRole: t('quotes:brandominus.authorRole'),
      imgAlt: t('quotes:brandominus.imgAlt'),
      citation: t('quotes:brandominus.quote'),
    },
    {
      id: 'buroImagin',
      // slug: 'buro-imagin',
      logoSrc: ImageSourceHelper.fromUrl(`${s3BucketUrl}/stories/buro-imagin-thumbnail.webp`),
      author: 'Kenny Braeckman',
      authorRole: t('quotes:buroImagin.authorRole'),
      imgAlt: t('quotes:buroImagin.imgAlt'),
      citation: t('quotes:buroImagin.quote'),
    },
    {
      id: 'canalWeb',
      // slug: 'canal-web',
      logoSrc: ImageSourceHelper.fromUrl(`${s3BucketUrl}/stories/canal-web-thumbnail.webp`),
      author: 'Emmanuel de la Bouillerie',
      authorRole: t('quotes:canalWeb.authorRole'),
      imgAlt: t('quotes:canalWeb.imgAlt'),
      citation: t('quotes:canalWeb.quote'),
    },
    {
      id: 'duvalBranding',
      // slug: 'duval-branding',
      logoSrc: ImageSourceHelper.fromUrl(`${s3BucketUrl}/stories/duval-branding-thumbnail.webp`),
      author: 'Marc Wellens',
      authorRole: t('quotes:duvalBranding.authorRole'),
      imgAlt: t('quotes:duvalBranding.imgAlt'),
      citation: t('quotes:duvalBranding.quote'),
    },
    {
      id: 'esign',
      // slug: 'esign',
      logoSrc: ImageSourceHelper.fromUrl(`${s3BucketUrl}/stories/esign-thumbnail.webp`),
      author: 'Matthias Stephens',
      authorRole: t('quotes:esign.authorRole'),
      imgAlt: t('quotes:esign.imgAlt'),
      citation: t('quotes:esign.quote'),
    },
    {
      id: 'eurosAgency',
      // slug: 'euros-agency',
      logoSrc: ImageSourceHelper.fromUrl(`${s3BucketUrl}/stories/euros-agency-thumbnail.webp`),
      author: 'Mathieu Collet',
      authorRole: t('quotes:eurosAgency.authorRole'),
      imgAlt: t('quotes:eurosAgency.imgAlt'),
      citation: t('quotes:eurosAgency.quote'),
    },
    {
      id: 'insign',
      logoSrc: ImageSourceHelper.fromUrl(`${s3BucketUrl}/stories/insign-thumbnail.webp`),
      author: 'Guillaume Rotrou',
      authorRole: t('quotes:insign.authorRole'),
      imgAlt: t('quotes:insign.imgAlt'),
      citation: t('quotes:insign.quote'),
    },
    {
      id: 'livingStone',
      slug: 'livingstone',
      logoSrc: ImageSourceHelper.fromUrl(`${s3BucketUrl}/stories/livingstone-thumbnail.webp`),
      author: 'Anne-Mie Vansteelant',
      authorRole: t('quotes:livingStone.authorRole'),
      imgAlt: t('quotes:livingStone.imgAlt'),
      citation: t('quotes:livingStone.quote'),
    },
    {
      id: 'mardi',
      // slug: 'mardi',
      logoSrc: ImageSourceHelper.fromUrl(`${s3BucketUrl}/stories/mardi-thumbnail.webp`),
      author: 'Sylvie Lillywhite',
      authorRole: t('quotes:mardi.authorRole'),
      imgAlt: t('quotes:mardi.imgAlt'),
      citation: t('quotes:mardi.quote'),
    },
    {
      id: 'misterJekyll',
      // slug: 'mister-jekyll',
      logoSrc: ImageSourceHelper.fromUrl(`${s3BucketUrl}/stories/mister-jekyll-thumbnail.webp`),
      author: 'Nicolas Gaete Del Rio',
      authorRole: t('quotes:misterJekyll.authorRole'),
      imgAlt: t('quotes:misterJekyll.imgAlt'),
      citation: t('quotes:misterJekyll.quote'),
    },
    {
      id: 'feten',
      // slug: 'feten',
      logoSrc: ImageSourceHelper.fromUrl(`${s3BucketUrl}/stories/feten-thumbnail.webp`),
      author: 'Leónidas Fuster',
      authorRole: t('quotes:feten.authorRole'),
      imgAlt: t('quotes:feten.imgAlt'),
      citation: t('quotes:feten.quote'),
    },
    {
      id: 'blubber',
      // slug: 'blubber',
      logoSrc: ImageSourceHelper.fromUrl(`${s3BucketUrl}/stories/blubber-thumbnail.webp`),
      author: 'Rubén Gago',
      authorRole: t('quotes:blubber.authorRole'),
      imgAlt: t('quotes:blubber.imgAlt'),
      citation: t('quotes:blubber.quote'),
    },
    {
      id: 'everythink',
      // slug: 'everythink',
      logoSrc: ImageSourceHelper.fromUrl(`${s3BucketUrl}/stories/everythink-thumbnail.webp`),
      author: 'Virginia Frutos',
      authorRole: t('quotes:everythink.authorRole'),
      imgAlt: t('quotes:everythink.imgAlt'),
      citation: t('quotes:everythink.quote'),
    },
    {
      id: 'madkings',
      // slug: 'madkings',
      logoSrc: ImageSourceHelper.fromUrl(`${s3BucketUrl}/stories/mad-kings-thumbnail.webp`),
      author: 'Thomas Paris',
      authorRole: t('quotes:madkings.authorRole'),
      imgAlt: t('quotes:madkings.imgAlt'),
      citation: t('quotes:madkings.quote'),
    },
    {
      id: 'echoStudio',
      // slug: 'echo',
      logoSrc: ImageSourceHelper.fromUrl(`${s3BucketUrl}/stories/echo-studio-thumbnail.webp`),
      author: 'Cédric Menier',
      citation: t('quotes:echoStudio.quote'),
      imgAlt: t('quotes:echoStudio.imgAlt'),
      authorRole: t('quotes:echoStudio.authorRole'),
    },
    {
      id: 'fiftyFifty',
      // slug: 'fifty-fifty',
      logoSrc: ImageSourceHelper.fromUrl(`${s3BucketUrl}/stories/studio-fifty-fifty-thumbnail.webp`),
      author: 'Thomas Van Den Driessche',
      citation: t('quotes:fiftyFifty.quote'),
      imgAlt: t('quotes:fiftyFifty.imgAlt'),
      authorRole: t('quotes:fiftyFifty.authorRole'),
    },
  ];
};
